import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { v4 as uuidv4 } from 'uuid';
import { grey, red } from '@material-ui/core/colors';
import {
  AuthoredEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
  listItemsFactory,
} from '@eas/common-web';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TreeStructureToolbarButton } from '@components/tree-structure/tree-structure-toolbar-button';
import { Me, PrimaryJournal } from '@models';
import {
  EvidenceApiUrl,
  ExportTag,
  JournalTypeEnum,
  Permission,
  PrimaryJournalState,
} from '@enums';
import { handleApiErrors } from '@utils/error-handler';
import { useEvidencePermission } from '@utils/permission-hook';
import { useUserAssignments } from '@utils/use-user-assignments';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { PrimaryJournalActionbar } from './primary-journal-actionbar';
import { useColumns } from './primary-journal-columns';
import { Fields } from './primary-journal-fields';
import { usePrimaryJournalHookedSources } from './primary-journal-hooked-sources';
import { useValidationSchema } from './primary-journal-schema';

export function PrimaryJournals() {
  const intl = useIntl();

  const { user } = useContext<UserContext<Me>>(UserContext);
  const { isPrimaryJournalAssigned } = useUserAssignments();
  const { showSnackbar } = useContext(SnackbarContext);
  const { handleSwitchSupplier } = useUserContextSwitch();

  const validationSchema = useValidationSchema();

  const defaultPrimaryJournal: PrimaryJournal = {
    id: uuidv4(),
    signatures: [],
    journalType: JournalTypeEnum.PRIMARY,
    tenant: user?.tenant,
  };

  const permissions = useEvidencePermission({
    options: {
      NEW: [Permission.PrimaryJournal.PRIMARY_JOURNAL_CREATE],
      EDIT: [Permission.PrimaryJournal.PRIMARY_JOURNAL_UPDATE],
      REMOVE: [Permission.PrimaryJournal.PRIMARY_JOURNAL_DELETE],
      REFRESH: [Permission.PrimaryJournal.PRIMARY_JOURNAL_DETAIL],
      SHARE: [Permission.PrimaryJournal.PRIMARY_JOURNAL_DETAIL],
      CHECK: [
        Permission.PrimaryJournal.PRIMARY_JOURNAL_CREATE,
        Permission.PrimaryJournal.PRIMARY_JOURNAL_UPDATE,
      ],
      SAVE: [
        Permission.PrimaryJournal.PRIMARY_JOURNAL_CREATE,
        Permission.PrimaryJournal.PRIMARY_JOURNAL_UPDATE,
      ],
      CLOSE: [
        Permission.PrimaryJournal.PRIMARY_JOURNAL_CREATE,
        Permission.PrimaryJournal.PRIMARY_JOURNAL_UPDATE,
      ],
    },
  });

  const evidence = AuthoredEvidence.useAuthoredEvidence<PrimaryJournal>({
    version: 9,
    identifier: 'PRIMARY_JOURNAL',
    apiProps: {
      url: EvidenceApiUrl.PRIMARY_JOURNAL,
      hookedSources: usePrimaryJournalHookedSources,
      listItems: listItemsFactory<PrimaryJournal>({
        postProcess: async (journalSource) => {
          handleSwitchSupplier();
          return journalSource;
        },
      }),
      ...handleApiErrors(regionApiErrors.PRIMARY_JOURNAL, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__PRIMARY_JOURNAL__TABLE__TITLE',
        defaultMessage: 'Hlavný denník',
      }),
      createRowStyle: (item) => {
        return item.state === PrimaryJournalState.CANCELLED
          ? { color: red[600] }
          : item.state === PrimaryJournalState.SIGNED
          ? { color: grey[600] }
          : {};
      },
      reportTag: ExportTag.PRIMARY_JOURNAL_GRID,
      toolbarProps: {
        before: <TreeStructureToolbarButton isPrimary />,
        after: (
          <MultipleDeleteTableToolbarButton
            deletePermissionKey={
              Permission.PrimaryJournal.PRIMARY_JOURNAL_DELETE
            }
          />
        ),
      },
      showNamedSettingsButton: true,
      showBulkActionButton: false,
    },
    detailProps: {
      initNewItem: () => defaultPrimaryJournal,
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,

      lock: (values) => {
        if (values && user?.tenant?.id !== values?.tenant?.id)
          return 'Zamknuté. Nesprávne interné nastavenie vlastníka.';

        if (values && !isPrimaryJournalAssigned(values?.id)) return true;

        if (
          values?.state &&
          [
            PrimaryJournalState.CANCELLED,
            PrimaryJournalState.CLOSED,
            PrimaryJournalState.SIGNED,
          ].includes(values?.state)
        ) {
          return 'Zamknuté. Denný záznam nie je možné editovat v tomto stave';
        }

        return false;
      },
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__PRIMARY_JOURNAL__DETAIL__TITLE',
          defaultMessage: 'Hlavný denník',
        }),
        showBtn: permissions,
        disableBtn: () => !user?.tenant,
        ActionBar: user?.tenant ? PrimaryJournalActionbar : () => <></>,
      },
    },
  });

  return <Evidence {...evidence} />;
}
