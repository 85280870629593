import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { grey } from '@material-ui/core/colors';
import {
  AuthoredEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
  getItemFactory,
} from '@eas/common-web';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TreeStructureContext } from '@components/tree-structure/tree-structure-provider';
import { TreeStructureToolbarButton } from '@components/tree-structure/tree-structure-toolbar-button';
import { JournalEntry, Me, PartialJournal, PrimaryJournal } from '@models';
import {
  ContextSwitchSupplierType,
  EvidenceApiUrl,
  ExportTag,
  JournalEntryState,
  JournalTypeEnum,
  Permission,
  PrimaryJournalState,
} from '@enums';
import { useEvidencePermission } from '@utils/permission-hook';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { JournalEntryActionbar } from './journal-entry-actionbar';
import { useGetWeather } from './journal-entry-api';
import { useColumns } from './journal-entry-columns';
import { Fields } from './journal-entry-fields';
import { useJournalEntryHookedSources } from './journal-entry-hooked-sources';
import { useValidationSchema } from './journal-entry-schema';

export function JournalEntries() {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { journalEntryPrefilters } = useContext(TreeStructureContext);

  const intl = useIntl();
  const { handleSwitchSupplier } = useUserContextSwitch();

  const getWeatherApiCall = useGetWeather();
  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [],
      EDIT: [Permission.JournalEntry.JOURNAL_ENTRY_UPDATE],
      REMOVE: [Permission.JournalEntry.JOURNAL_ENTRY_DELETE],
      REFRESH: [Permission.JournalEntry.JOURNAL_ENTRY_DETAIL],
      SHARE: [Permission.JournalEntry.JOURNAL_ENTRY_DETAIL],
      CHECK: [Permission.JournalEntry.JOURNAL_ENTRY_UPDATE],
      SAVE: [Permission.JournalEntry.JOURNAL_ENTRY_UPDATE],
      CLOSE: [Permission.JournalEntry.JOURNAL_ENTRY_UPDATE],
    },
  });

  const hasCorrectSupplierAndTenant = (journalEntry: JournalEntry) => {
    if (journalEntry.journal?.journalType === JournalTypeEnum.PARTIAL) {
      const journal = journalEntry.journal as PartialJournal;
      return (
        journal.supplier?.id === user?.supplier?.id &&
        journal.parent?.tenant?.id === user?.tenant?.id
      );
    }

    const journal = journalEntry.journal as PrimaryJournal;
    return journal.tenant?.id === user?.tenant?.id;
  };

  const evidence = AuthoredEvidence.useAuthoredEvidence<JournalEntry>({
    version: 6,
    identifier: 'JOURNAL_ENTRIES',
    apiProps: {
      url: EvidenceApiUrl.JOURNAL_ENTRY,
      getItem: getItemFactory<JournalEntry>({
        postProcess: (journalEntry) => {
          if (journalEntry.journal?.journalType === JournalTypeEnum.PARTIAL) {
            const partialJournal = journalEntry.journal as PartialJournal;
            handleSwitchSupplier(
              partialJournal.supplier?.id,
              ContextSwitchSupplierType.PARTIAL_JOURNAL_ENTRY
            );
          } else {
            handleSwitchSupplier(
              undefined,
              ContextSwitchSupplierType.PRIMARY_JOURNAL_ENTRY
            );
          }
          return getWeatherApiCall(journalEntry);
        },
      }),
      hookedSources: useJournalEntryHookedSources,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__JOURNAL_ENTRY__TABLE__TITLE',
        defaultMessage: 'Denné záznamy',
      }),
      createRowStyle: (item) => {
        return item.state === JournalEntryState.SIGNED
          ? { color: grey[600] }
          : {};
      },
      defaultSorts: [
        {
          field: 'validityDate',
          datakey: 'validityDate',
          order: 'DESC',
          type: 'FIELD',
        },
      ],
      defaultPreFilters: journalEntryPrefilters,
      reportTag: ExportTag.JOURNAL_ENTRY_GRID,
      showNamedSettingsButton: true,
      showBulkActionButton: false,
      toolbarProps: {
        before: <TreeStructureToolbarButton isEntry />,
        after: (
          <MultipleDeleteTableToolbarButton
            deletePermissionKey={Permission.JournalEntry.JOURNAL_ENTRY_DELETE}
          />
        ),
      },
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      lock: (values) => {
        if (values && !hasCorrectSupplierAndTenant(values))
          return 'Zamknuté. Nesprávne interné nastavenie dodávateľa alebo vlastníka.';

        if (values?.state === JournalEntryState.CLOSED)
          return 'Zamknuté. Denný záznam je v stave zatvorený.';

        if (values?.state === JournalEntryState.SIGNED)
          return 'Zamknuté. Denný záznam je v stave podpísaný.';

        // Locking entry if parent journal is locked, because of cancelling
        if (values?.journal?.state === PrimaryJournalState.CANCELLED)
          return 'Zamknuté. Niektorý nadradený denník bol zrušený.';

        return false;
      },
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__JOURNAL_ENTRY__DETAIL__TITLE',
          defaultMessage: 'Denné záznamy',
        }),
        showBtn: permissions,
        ActionBar: JournalEntryActionbar,
      },
    },
  });

  return <Evidence {...evidence} />;
}
