import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { regionApiErrors } from 'src/enums/messages/api-errors';
import { PartialJournalState } from 'src/enums/partial-journal-state';
import { grey, red } from '@material-ui/core/colors';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
  createItemFactory,
  getItemFactory,
} from '@eas/common-web';
import { MultipleDeleteTableToolbarButton } from '@components/multiple-delete-table-toolbar-button/multiple-delete-table-toolbar-button';
import { TreeStructureContext } from '@components/tree-structure/tree-structure-provider';
import { TreeStructureToolbarButton } from '@components/tree-structure/tree-structure-toolbar-button';
import { Me, PartialJournal } from '@models';
import {
  ContextSwitchSupplierType,
  EvidenceApiUrl,
  ExportTag,
  Permission,
  PrimaryJournalState,
} from '@enums';
import { handleApiErrors } from '@utils/error-handler';
import { useEvidencePermission } from '@utils/permission-hook';
import { useUserContextSwitch } from '@utils/user-context-switch';
import { PartialJournalActionbar } from './partial-journal-actionbar';
import { useColumns } from './partial-journal-columns';
import { Fields } from './partial-journal-fields';
import { usePartialJournalHookedSources } from './partial-journal-hooked-sources';
import { useValidationSchema } from './partial-journal-schema';

export function PartialJournals() {
  const { user } = useContext<UserContext<Me>>(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { structure } = useContext(TreeStructureContext);

  const intl = useIntl();
  const { handleSwitchSupplier } = useUserContextSwitch();

  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission({
    options: {
      NEW: [],
      EDIT: [Permission.PartialJournal.PARTIAL_JOURNAL_UPDATE],
      REMOVE: [Permission.PartialJournal.PARTIAL_JOURNAL_DELETE],
      REFRESH: [Permission.PartialJournal.PARTIAL_JOURNAL_DETAIL],
      SHARE: [Permission.PartialJournal.PARTIAL_JOURNAL_DETAIL],
      CHECK: [Permission.PartialJournal.PARTIAL_JOURNAL_UPDATE],
      SAVE: [Permission.PartialJournal.PARTIAL_JOURNAL_UPDATE],
      CLOSE: [Permission.PartialJournal.PARTIAL_JOURNAL_UPDATE],
    },
  });

  const evidence = AuthoredEvidence.useAuthoredEvidence<PartialJournal>({
    version: 7,
    identifier: 'PARTIAL_JOURNAL',
    apiProps: {
      url: EvidenceApiUrl.PARTIAL_JOURNAL,
      hookedSources: usePartialJournalHookedSources,
      getItem: getItemFactory<PartialJournal>({
        postProcess: (journal) => {
          handleSwitchSupplier(
            journal.supplier?.id,
            ContextSwitchSupplierType.PARTIAL_JOURNAL
          );
          return journal;
        },
      }),
      createItem: createItemFactory<PartialJournal>({
        postProcess: (journal) => {
          handleSwitchSupplier(
            journal.supplier?.id,
            ContextSwitchSupplierType.PARTIAL_JOURNAL
          );
          return journal;
        },
      }),
      ...handleApiErrors(regionApiErrors.PARTIAL_JOURNAL, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ESD__PARTIAL_JOURNAL__TABLE__TITLE',
        defaultMessage: 'Dielčie denníky',
      }),
      createRowStyle: (item) => {
        return item.state === PartialJournalState.CANCELLED
          ? { color: red[600] }
          : item.state === PartialJournalState.SIGNED
          ? { color: grey[600] }
          : {};
      },
      reportTag: ExportTag.PARTIAL_JOURNAL_GRID,
      showNamedSettingsButton: true,
      showBulkActionButton: false,
      toolbarProps: {
        before: <TreeStructureToolbarButton isPartial />,
        after: (
          <MultipleDeleteTableToolbarButton
            deletePermissionKey={
              Permission.PartialJournal.PARTIAL_JOURNAL_DELETE
            }
          />
        ),
      },
      defaultPreFilters: structure?.primaryJournal
        ? [
            {
              field: 'parent.id',
              operation: ApiFilterOperation.EQ,
              value: structure.primaryJournal,
            },
          ]
        : [],
    },
    detailProps: {
      lock: (values) => {
        if (
          values &&
          (user?.supplier?.id !== values?.supplier?.id ||
            user?.tenant?.id !== values?.parent?.tenant?.id)
        )
          return 'Zamknuté. Nesprávne interné nastavenie dodávateľa alebo vlastníka.';

        if (
          values?.state === PartialJournalState.CLOSED ||
          values?.state === PartialJournalState.SIGNED ||
          values?.state === PartialJournalState.CANCELLED
        )
          return 'Zamknuté. Denník nie je možné editovat v tomto stave';

        // Locking partial if parent primary is cancelled
        if (values?.parent?.state === PrimaryJournalState.CANCELLED)
          return 'Zamknuté. Denník bol zrušený.';

        return false;
      },

      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ESD__PARTIAL_JOURNAL__DETAIL__TITLE',
          defaultMessage: 'Dielčí denník',
        }),
        showBtn: permissions,
        ActionBar: PartialJournalActionbar,
      },
    },
  });

  return <Evidence {...evidence} />;
}
